export const tr = {
  name: "Turkish",
  flagCode: "tr",
  locale: "tr-TR",
  project: { name: "Driver Signup" },
  key: "tr",
  validations: {
    PLEASE_SELECT_CITY: "Lütfen Şehri seçin.",
    PLEASE_SELECT_SERVICE: "Lütfen Hizmet'i seçin.",
    PLEASE_SELECT_DRIVER_TYPE: "Lütfen Sürücü Türünü seçin.",
    PLEASE_INPUT_FIRSTNAME: "Lütfen adınızı girin.",
    PLEASE_INPUT_LASTNAME: "Lütfen soyadınızı girin.",
    PLEASE_INPUT_EMAIL: "Lütfen e -postanızı girin.",
    INVALID_EMAIL: "Lütfen geçerli bir e -posta belirtin.",
    PLEASE_INPUT_MOBILE_PHONE: "Lütfen telefon numaranızı girin.",
    INVALID_PHONE_NUMBER: "Lütfen geçerli bir telefon numarası belirtin.",
    PHONE_NUNBER_EXITS:
      "Zaten başka bir hesapla kullanımda olan telefon numarası.",
    USERNAME_EXITS: "Bu kullanıcı adı zaten sistemimizde var.",
    ERROR_INPUT_VALID_USERNAME:
      "Kullanıcı adı yalnızca daha düşük harfler ve sayılar içerebilir ve 3 ila 20 karakter uzunluğunda olmalıdır",
    FIELD_IS_REQUIRED: "Bu alan gereklidir.",
    REFERRAL_CODE_INVALID: "Tavsiye kodu geçersiz.",
    EMAIL_EXITS: "Bu e-posta zaten mevcut. Lütfen başka bir tane girin.",
    INVALID_USERNAME: "Lütfen geçerli bir kullanıcı adı belirtin.",
    FIELD_IS_REQUIRED_BETWEEN_3_TO_20:
      "Kullanıcı adı 3 ila 20 karakter arasında olmalıdır.",
    PHONE_NUNBER_EXITS_IN_GROUP: "",
  },
  errors: {
    3034: "Bu telefon sistemde mevcuttu.Lütfen başka bir tane girin.",
    4001: "Plaka çıkıyor",
    NETWORK_ERROR: "Hata: Ağ Hatası",
  },
  general: {
    CONTINUE: "Devam etmek",
    STEP_COMPLETE: "{0}/{1} tamamlandı",
    STEP_HEADER: "Adım {0}",
    BACK: "Geri",
    NEXT: "Sonraki",
    SUBMIT: "Gönder",
    Clear_Selected_Items: "Seçilen öğeleri temizleyin",
    no_results: "Sonuç yok",
    add_new_item: "Yeni ekle",
  },
  home: {
    BECOME: "%{name} sürücüsü olun.",
    HEADING: "Başlamak için temel bilgilerinizi girin",
    APPLY_NOW: "Kaydol",
    INDIVIDUAL_CAR_GOJO: "Taşımacılık/Taksi - Aracım Kendi",
    INDIVIDUAL_CAR: "Taşımacılık - Aracım Kendi",
    COMPANY_CAR: "Ulaşım - Şirket Aracı",
    DELIVERY_INDIVIDUAL_CAR: "Teslimat - Aracım sahibim",
    DELIVERY_COMPANY_CAR: "Teslimat - Şirket Aracı",
    PLACEHOVER_SELECT_PRE_CITY: "Şehir",
    PLACEHOVER_SELECT_REGISTRATION_TYPE: "Sürücü Türünü Seçin",
    USERNAME: "Kullanıcı adı",
    FIRST_NAME: "Ad",
    LAST_NAME: "Soyad",
    EMAIL: "E -posta (isteğe bağlı)",
    PHONE_NUMBER: "Cep numarası",
    REFERRAL_CODE: "Tavsiye Kodu (isteğe bağlı)",
    I_AGREE_TO: "Kayıt yaparak okudum ve kabul ettim",
    TERMS_OF_USE: "Hizmet Şartları",
    AND: "Ve",
    PRIVACY_POLICY: "Gizlilik Politikası",
    I_AGREE_TO_AFTER: ".",
    Company: "Tedarikçi",
  },
  finish: {
    FINISH_1: "%{name} ile kaydolduğunuz için teşekkür ederiz!",
    FINISH_3: "Başlamak için şimdi giriş yapın!",
    FINISH_USE_PASSWORD_1: "Neredeyse orada!",
    FINISH_USE_PASSWORD_2:
      "Hesabınızı etkinleştirmek ve iş almak için lütfen şu adımları izleyin:",
    FINISH_USE_PASSWORD_3: "1. Kayıtlı telefon numaranızla giriş yapın",
    FINISH_USE_PASSWORD_4:
      "2. Şifre: <span style = 'font-weight: kalın'>12345678</span>",
    FINISH_USE_PASSWORD_5: "3. Başvurunuzu tamamlayın",
    OK: "Giriş",
    downloadApp:
      "Download <span style='color: %{color}'> %{name}</span> Sürücü uygulaması şimdi ve kayıtlı telefon numaranızla giriş yapın.",
  },
  datePlaceHolder: "MM/DD/YYYY",
};
