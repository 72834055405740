export const de = {
  name: "German",
  flagCode: "de",
  locale: "de-DE",
  project: { name: "Driver Signup" },
  key: "de",
  validations: {
    PLEASE_SELECT_CITY: "Bitte wählen Sie eine Stadt aus.",
    PLEASE_SELECT_SERVICE: "Bitte wählen Sie den Dienst aus.",
    PLEASE_SELECT_DRIVER_TYPE: "Bitte wählen Sie den Fahrertyp aus.",
    PLEASE_INPUT_FIRSTNAME: "Bitte geben Sie Ihren Vornamen ein.",
    PLEASE_INPUT_LASTNAME: "Bitte geben Sie Ihren Nachnamen ein.",
    PLEASE_INPUT_EMAIL: "Bitte geben Sie Ihre E-Mail ein.",
    INVALID_EMAIL: "Bitte geben Sie eine gültige E-Mail an.",
    PLEASE_INPUT_MOBILE_PHONE: "Bitte geben Sie Ihre Telefonnummer ein.",
    INVALID_PHONE_NUMBER: "Bitte geben Sie eine gültige Telefonnummer an.",
    PHONE_NUNBER_EXITS:
      "Telefonnummer wird bereits mit einem anderen Konto verwendet.",
    USERNAME_EXITS: "Dieser Benutzername existiert bereits in unserem System.",
    ERROR_INPUT_VALID_USERNAME:
      "Der Benutzername darf nur Kleinbuchstaben und Zahlen enthalten und muss zwischen 3 und 20 Zeichen lang sein",
    FIELD_IS_REQUIRED: "Dieses Feld ist erforderlich.",
    REFERRAL_CODE_INVALID: "Der Empfehlungscode ist ungültig.",
    EMAIL_EXITS: "Diese E-Mail existiert bereits. Bitte geben Sie eine andere ein.",
    INVALID_USERNAME: "Bitte geben Sie einen gültigen Benutzernamen an.",
    FIELD_IS_REQUIRED_BETWEEN_3_TO_20:
      "Benutzername muss zwischen 3 und 20 Zeichen lang sein.",
    PHONE_NUNBER_EXITS_IN_GROUP: "",
  },
  errors: {
    3034: "Dieses Telefon existiert bereits im System. Bitte geben Sie ein anderes ein.",
    4001: "Das Kennzeichen existiert bereits",
    NETWORK_ERROR: "Fehler: Netzwerkfehler",
  },
  general: {
    CONTINUE: "Fortfahren",
    STEP_COMPLETE: "{0}/{1} Abgeschlossen",
    STEP_HEADER: "Schritt {0}",
    BACK: "Zurück",
    NEXT: "Weiter",
    SUBMIT: "Einreichen",
    Clear_Selected_Items: "Ausgewählte Elemente löschen",
    no_results: "Keine Ergebnisse",
    add_new_item: "Neu hinzufügen",
  },
  home: {
    BECOME: "Werden Sie ein %{name} Fahrer.",
    HEADING: "Geben Sie Ihre grundlegenden Informationen ein, um zu beginnen.",
    APPLY_NOW: "Anmelden",
    INDIVIDUAL_CAR_GOJO: "Transport/Taxi - Ich besitze das Fahrzeug",
    INDIVIDUAL_CAR: "Transport - Ich besitze das Fahrzeug",
    COMPANY_CAR: "Transport - Firmenfahrzeug",
    DELIVERY_INDIVIDUAL_CAR: "Lieferung - Ich besitze das Fahrzeug",
    DELIVERY_COMPANY_CAR: "Lieferung - Firmenfahrzeug",
    PLACEHOVER_SELECT_PRE_CITY: "Stadt",
    PLACEHOVER_SELECT_REGISTRATION_TYPE: "Fahrertyp auswählen",
    USERNAME: "Benutzername",
    FIRST_NAME: "Vorname",
    LAST_NAME: "Nachname",
    EMAIL: "E-Mail (optional)",
    PHONE_NUMBER: "Mobilnummer",
    REFERRAL_CODE: "Empfehlungscode (optional)",
    I_AGREE_TO: "Durch die Registrierung habe ich die",
    TERMS_OF_USE: "Nutzungsbedingungen",
    AND: "und der",
    PRIVACY_POLICY: "Datenschutzrichtlinie",
    I_AGREE_TO_AFTER: "gelesen und stimme ihnen zu.",
    Company: "Anbieter",
  },
  finish: {
    FINISH_1: "Vielen Dank, dass Sie sich bei %{name} angemeldet haben!",
    FINISH_3: "Melden Sie sich jetzt an, um zu beginnen!",
    FINISH_USE_PASSWORD_1: "Fast geschafft!",
    FINISH_USE_PASSWORD_2:
      "Um Ihr Konto zu aktivieren und Jobs zu erhalten, folgen Sie bitte diesen Schritten:",
    FINISH_USE_PASSWORD_3:
      "1. Melden Sie sich mit Ihrer registrierten Telefonnummer an",
    FINISH_USE_PASSWORD_4:
      "2. Passwort: <span style='font-weight: bold'>12345678</span>",
    FINISH_USE_PASSWORD_5: "3. Vervollständigen Sie Ihre Bewerbung",
    OK: "Anmelden",
    downloadApp:
      "Laden Sie jetzt die <span style='color: %{color}' >%{name}</span> Fahrer-App herunter und melden Sie sich mit Ihrer registrierten Telefonnummer an.",
  },
  datePlaceHolder: "MM/DD/YYYY",
};
