import { en } from "./source/en";
import { enGB } from "./source/enGB";
import { fr } from "./source/fr";
import { pt } from "./source/pt";
import { vi } from "./source/vi";
import { id } from "./source/id";
import { es } from "./source/es";
import { de } from "./source/de";
import { ur } from "./source/ur";
import { urRO } from "./source/urRO";
import { zhHK } from "./source/zhHK";
import { zhCN } from "./source/zhCN";
import { ms } from "./source/ms";
import { ar } from "./source/ar";
import { nn } from "./source/nn";
import { nb } from "./source/nb";
import { am } from "./source/am";
import { ro } from "./source/ro";
import { th } from "./source/th";
import { ta } from "./source/ta";
import { te } from "./source/te";
import { hi } from "./source/hi";
import { kn } from "./source/kn";
import { ka } from "./source/ka";
import { kk } from "./source/kk";
import { ru } from "./source/ru";
import { ja } from "./source/ja";
import { lv } from "./source/lv";
import { tl } from "./source/tl";
import { tr } from "./source/tr";
import { ku } from "./source/ku";
import { sv } from "./source/sv";

function getLanguage() {
  return {
    en,
    enGB,
    vi,
    fr,
    pt,
    id,
    es,
    de,
    ur,
    urRO,
    zhHK,
    zhCN,
    ms,
    ar,
    nn,
    nb,
    am,
    ro,
    th,
    ta,
    hi,
    te,
    kn,
    ka,
    kk,
    ru,
    ja,
    lv,
    tl,
    tr,
    ku,
    sv
  };
}

export const translations = getLanguage();
